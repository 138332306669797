import React, { Fragment, useEffect, useReducer } from 'react';
import Button from '../button/button';
import styled from 'styled-components';
import { MdGpsFixed } from 'react-icons/md';
import getLocationFromBrowser from '../../helpers/getLocationFromBrowser';
import Loader from '../loader';

const SEARCH_LOCATION = 'SEARCH_LOCATION';
const SEARCH_COMPLETE = 'SEARCH_COMPLETE';
const ERROR = 'ERROR';

const reducer = (state, action) => {
  switch (action.type) {
    case SEARCH_LOCATION:
      return {
        ...state,
        error: false,
        result: null,
        searching: true,
        loading: true,
      };
    case SEARCH_COMPLETE:
      return {
        ...state,
        loading: false,
        result: action.payload,
        searching: false,
      };
    default:
    case ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
  }
};

const initialState = {
  loading: false,
  location: null,
  searching: false,
};

const useGetLocation = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!state.searching) {
      return;
    }

    (async () => {
      try {
        const results = await getLocationFromBrowser();
        console.log('results!', results.coords);
        dispatch({
          type: SEARCH_COMPLETE,
          payload: {
            lat: results.coords.latitude,
            lng: results.coords.longitude,
          },
        });
      } catch (err) {
        dispatch({ type: ERROR, error: err });
      }
    })();
  }, [dispatch]);
  return { dispatch, state };
};

const Text = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
`;

const LocationButton = ({ handleLocationFromBrowser }) => {
  const { dispatch, state } = useGetLocation();

  useEffect(() => {
    if (!state.result) {
      return;
    }
    handleLocationFromBrowser(state.result);
  }, [state.result]);

  return (
    <Fragment>
      <Button
        disabled={state.loading}
        type="button"
        onClick={() => dispatch({ type: SEARCH_LOCATION })}
      >
        {!state.loading ? <MdGpsFixed /> : <Loader size="15px" />}
      </Button>
      {state.error && <Text>Helaas, we konden jouw locatie niet vinden.</Text>}
    </Fragment>
  );
};

export default LocationButton;
