import React from 'react';
import PaneNavigation from '../components/paneNavigation/paneNavigation';
import LabeledInput from '../components/labeledInput';
import Button from '../components/button/button';
import { AppConsumer } from '../context';
import styled from 'styled-components';
import { media } from '../helpers/media';

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;

  ${media.medium`
    button {
      width: 100%;
    }
  `};
`;

const TextPane = ({ onClose }) => (
  <AppConsumer>
    {({
      title,
      subtitle,
      lat,
      lng,
      handleUpdate,
      handleUpdateLocation,
      placenames,
      useCoordinates,
      setUseCoordinates,
    }) => (
      <PaneNavigation onClose={onClose}>
        <h2>Wijzig je teksten</h2>
        <LabeledInput
          label="Titel"
          value={title}
          onChange={event => handleUpdate('title', event.target.value)}
        />
        <LabeledInput
          label="Subtitel"
          value={subtitle}
          onChange={event => handleUpdate('subtitle', event.target.value)}
          onKeyUp={() => handleUpdate('useCoordinates', false)}
        />
        <ButtonContainer>
          <Button
            primary={useCoordinates}
            onClick={() => setUseCoordinates(true)}
          >
            Gebruik co&ouml;rdinaten als subtitel
          </Button>
        </ButtonContainer>
          <ButtonContainer>
        <Button
            primary={placenames}
            onClick={() => handleUpdate('placenames', !placenames)}
          >
            Plaatsnamen weergeven
          </Button>
        </ButtonContainer>
      </PaneNavigation>
    )}
  </AppConsumer>
);

export default TextPane;
