import React from 'react';
import { Complete, Location, Style, Texts } from '../../panes';

const panes = {
  styles: Style,
  location: Location,
  texts: Texts,
  complete: Complete,
};

const CurrentPane = ({ pane, onClose }) => {
  if (pane === null) {
    return null;
  }
  const P = panes[pane];
  return <P onClose={onClose} />;
};

export default CurrentPane;
