import React from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  background: #fff;
`;

const HeaderLogo = styled.img`
  height: 40px;
`;

export const Header = () => {
  return (
    <HeaderContainer>
      <HeaderLogo src="https://www.printmijnstad.nl/static/images/logo.png" />
    </HeaderContainer>
  );
};
