import React, { useState } from 'react';
import { AppConsumer } from '../context';
import PaneNavigation from '../components/paneNavigation/paneNavigation';
import Button from '../components/button/button';
import { MdSearch } from 'react-icons/md';
import LocationButton from '../components/locationButton';
import styled from 'styled-components';
import { media } from '../helpers/media';
import Input from '../components/input';

const cities = ['Amsterdam', 'Rotterdam', 'New York City', 'Barcelona'];

const LocationForm = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  button {
    margin-left: 5px;
  }
`;

const DefaultLocations = styled.div`
  display: flex;
  flex-wrap: wrap;

  button {
    margin-right: 5px;
    margin-bottom: 5px;
    width: calc(50% - 5px);
    
    ${media.medium`
     margin-right: 0;
     width: 100%;
    `}
  }
`;

const SearchInput = styled(Input)`
  width: initial !important;
  min-width: 0;
  flex-grow: 1;
`;

const LocationPane = ({ onClose }) => {
  const [city, setCity] = useState('');
  return (
    <AppConsumer>
      {({ handleSearchAddress, title, handleLocationFromBrowser }) => (
        <PaneNavigation onClose={onClose}>
          <h2>Kies je locatie</h2>
          <LocationForm
            onSubmit={e => {
              e.preventDefault();
              handleSearchAddress(city);
            }}
          >
            <SearchInput
              value={city}
              onChange={e => setCity(e.target.value)}
              placeholder="Jouw favoriete locatie"
            />
            <Button primary>
              <MdSearch />
            </Button>
            <LocationButton
              handleLocationFromBrowser={handleLocationFromBrowser}
            />
          </LocationForm>
          <h4>Of probeer een van onze favorieten!</h4>
          <DefaultLocations>
            {cities.map(city => (
              <Button
                key={city}
                selected={title === city}
                onClick={() => {
                  handleSearchAddress(city);
                }}
              >
                {city}
              </Button>
            ))}
          </DefaultLocations>
        </PaneNavigation>
      )}
    </AppConsumer>
  );
};

export default LocationPane;
