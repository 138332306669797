import React from 'react';
import LabelComponent from '../label';
import InputComponent from '../input';
import styled from 'styled-components';

const Label = styled(LabelComponent)`
  margin-top: 10px;
`;

const Input = styled(InputComponent)`
  margin-top: 10px;
`;

const LabeledInput = ({ label, ...inputProps }) => (
  <Label>
    {label}
    <Input {...inputProps} />
  </Label>
);

export default LabeledInput;
