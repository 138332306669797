import React, { Fragment } from 'react';
import styled from 'styled-components';
import { formats } from '../../../config';

export const VerticalLabel = styled.div`
  height: 3px;
  width: 3px;
  background: chartreuse;
  z-index: 10002;
  position: absolute;
  font-size: 24px;
  right: -10%;
  transform: rotate(-90deg);
  top: 50%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: #cccccc;
`;

export const VerticalSize = styled.div`
  height: 100%;
  width: 2px;
  position: absolute;
  background-color: #cccccc;
  right: -40px;
  top: 0;

  &:after {
    content: ' ';
    background-color: #cccccc;
    width: 15px;
    position: absolute;
    top: 0;
    height: 2px;
    left: -6.5px;
  }

  &:before {
    content: ' ';
    background-color: #cccccc;
    width: 15px;
    position: absolute;
    bottom: 0;
    height: 2px;
    left: -6.5px;
  }
`;

export const HorizontalSize = styled.div`
  width: 100%;
  position: absolute;
  bottom: -40px;
  background-color: #cccccc;
  height: 2px;
  left: 0;

  &:after {
    content: ' ';
    background-color: #cccccc;
    height: 15px;
    position: absolute;
    width: 2px;
    top: -6.5px;
    left: 0;
  }

  &:before {
    content: ' ';
    background-color: #cccccc;
    height: 15px;
    position: absolute;
    bottom: 0;
    width: 2px;
    top: -6.5px;
    right: 0;
  }
`;

export const HorizontalLabel = styled.div`
  position: absolute;
  color: #cccccc;
  font-size: 24px;
  bottom: -70px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const getSizeFromFormat = (search, horizontal) => {
  let [item] = formats.filter(({ size }) => {
    return size === search;
  });

  if (!item) {
    [item] = formats;
  }

  const { w, h } = item.mm;
  if (horizontal) {
    return [w, h];
  }
  return [h, w];
};

export const SizeLabels = ({ size, horizontal }) => {
  const [v, h] = getSizeFromFormat(size, horizontal);
  return (
    <Fragment>
      <VerticalSize />
      <VerticalLabel>{v} mm</VerticalLabel>
      <HorizontalSize />
      <HorizontalLabel>{h} mm</HorizontalLabel>
    </Fragment>
  );
};
