import React from 'react';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import { media } from '../../helpers/media';

const CloseButton = styled(MdClose)`
  position: absolute;
  top: -40px;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  right: 0;
  background-color: #f7f7f7;
  border-top: 1px solid #3337444d;
  border-left: 1px solid #3337444d;
  cursor: pointer;
  
  ${media.medium`
    display: none;
  `}
`;

const Pane = styled.div`
  z-index: 1001;
  border-top: 1px solid #3337444d;
  box-sizing: border-box;
  position: absolute;
  background-color: #f7f7f7;
  width: 100%;
  padding: 10px;
  bottom: calc(90px + 60px); // 90 for nav 60 for buy button
  
  ${media.medium`
    bottom: 0;
    display: inline-block;
    position: initial;
    height: calc(100vh - 60px - 60px); // - header - buy button
    width: 350px;
    position: relative;
    border-top: none;
    border-right: 1px solid #3337444d;
    border-bottom: 1px solid #3337444d;
    border-top: none;
  `}
`;

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 400px;
  
  ${media.medium`
    position: relative;
  `}
`;

const PaneNavigation = ({ isOpen, children, onClose }) => {
  return (
    <Container>
      <Pane isOpen={isOpen}>
        <CloseButton onClick={onClose} />
        {children}
      </Pane>
    </Container>
  );
};

export default PaneNavigation;
