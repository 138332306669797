import React, { Fragment } from 'react';
import { Bottom, Center, CenterBox, Coordinates, FrameInset, Gradient, Title } from "./components";

export const Map = ({
  children,
  scale,
  print,
  mapStyle,
  location,
  title,
  subtitle,
  overlay,
}) => {
  return (
    <Fragment>
      <FrameInset scale={scale}>{children}</FrameInset>
      {overlay === 'gradient' && (
        <Gradient scale={scale} print={true}>
          <Title scale={scale}>{title}</Title>
          <Coordinates scale={scale}>{subtitle}</Coordinates>
        </Gradient>
      )}
      {overlay === 'bottom' && (
        <Bottom scale={scale} print={true}>
          <Title scale={scale}>{title}</Title>
          <Coordinates scale={scale}>{subtitle}</Coordinates>
        </Bottom>
      )}
      {overlay === 'center' && (
        <Center scale={scale} print={true}>
          <CenterBox scale={scale}>
            <Title scale={scale}>{title}</Title>
            <Coordinates scale={scale}>{subtitle}</Coordinates>
          </CenterBox>
        </Center>
      )}
    </Fragment>
  );
};
