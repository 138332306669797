import React, { useEffect, useState, Fragment } from 'react';
import { Header } from '../components/header/header';
import { DesignedMap } from '../components/designedMap';
import { Nav } from '../components/nav';
import { AppConsumer } from '../context';
import CurrentPane from '../components/currentPane';
import styled from 'styled-components';
import { RotateButton } from '../components/rotateButton/rotateButton';
import { media, sizes } from '../helpers/media';
import BuyButton from '../components/buyButton/buyButton';

const NavContainer = styled.div`
  ${media.medium`
    display: flex;
  `}
`;

const isDesktopWidth = () => document.body.clientWidth >= sizes.medium;

const Main = () => {
  const [pane, setPane] = useState(null);

  useEffect(() => {
    if (isDesktopWidth() && pane === null) {
      setPane('location');
    }

    window.addEventListener('resize', () => {
      if (isDesktopWidth() && pane === null) {
        setPane('location');
      }
    });
  }, [pane]);

  return (
    <AppConsumer>
      {({
        print,
        handleUpdate,
        handleUpdateLocation,
        horizontal,
        ...props
      }) => (
        <Fragment>
          <BuyButton />
          <Header />
          <RotateButton
            onRotate={() => handleUpdate('horizontal', !horizontal)}
          />
          <DesignedMap
            scale={1}
            isPaneOpen={pane !== null}
            onZoom={zoom => handleUpdate('zoom', zoom)}
            onLocation={location => handleUpdateLocation(location)}
            horizontal={horizontal}
            {...props}
          />
          <NavContainer>
            <Nav
              selectedPane={pane}
              onClick={newPane => {
                if (isDesktopWidth()) {
                  setPane(newPane);
                  return;
                }
                setPane(newPane === pane ? null : newPane);
              }}
            />
            <CurrentPane pane={pane} onClose={() => setPane(null)} />
          </NavContainer>
        </Fragment>
      )}
    </AppConsumer>
  );
};

export default Main;
