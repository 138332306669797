import React from 'react';
import styled, { css } from 'styled-components';
import { MdCheck } from 'react-icons/md';

const Container = styled.div`
  cursor: pointer;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 64px;
  padding: 5px;
  justify-content: center;
  text-align: center;
`;

const Map = styled.img`
  width: 64px;
  height: 64px;
  overflow: hidden;
  border-radius: 25px;
  border: 4px solid transparent;
  box-sizing: border-box;
  
  ${({ active }) =>
    active &&
    css`
      border: 4px solid #a1d1fb;
    `}
`;

const Label = styled.p`
  padding: 0;
  margin: 0;
  font-size: 12px;
`;

const CheckContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: white;
  border-radius: 50%;
  border: 2px solid #a1d1fb;
`;

const Check = () => (
  <CheckContainer>
    <MdCheck />
  </CheckContainer>
);

const MapStyle = ({ mapStyleId = 'very-dark', active, style, onClick }) => (
  <Container onClick={onClick}>
    {active && <Check />}
    <Map
      active={active}
      src={`https://kaartenzijncool.printmijnstad.nl/${mapStyleId}/13/4207/2692.png`}
    />
  </Container>
);

export const OverlayStyle = ({
         mapStyleId = 'very-dark',
         active,
         style,
         onClick,
       }) => (
         <Container onClick={onClick}>
           {active && <Check />}
           <Map active={active} src={`/overlays/${mapStyleId}.png`} />
           <Label>{style}</Label>
         </Container>
       );

export default MapStyle;
