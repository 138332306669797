import styled, { css } from 'styled-components';
import { Map as MapComponent } from 'react-leaflet';

export const LeafletMap = styled(MapComponent)`
  height: 100%;
  .leaflet-control-attribution {
    display: none;
  }
`;

export const scalePx = (px, scale) => px * scale;

export const FakeMap = styled.div`
  position: relative;
  height: 100%;
`;

export const MapFrame = styled.div`
  font-family: "Open Sans", sans-serif !important;
  box-shadow: 8px 8px 50px rgba(0, 0, 0, 0.37);
  transform-origin: top center;
  transform: scale(${props => props.transformScale});
  //padding: 10px;
  //background-color: white;
  //border: 2px solid black;
  height: 877px;
  width: 620px;
  position: absolute;
  margin-top: 20px;
  .leaflet-container {
    font: inherit;
  }

  ${({ scale }) =>
    scale &&
    css`
      //border: ${scalePx(2, scale)}px solid black;
      //padding: ${scalePx(10, scale)}px;
      width: ${scalePx(620, scale)}px;
      height: ${scalePx(877, scale)}px;
    `}
  
  ${({ print }) =>
    print &&
    css`
      box-shadow: none;
      margin-top: 0;
    `}
  
  ${props =>
    props.horizontal &&
    css`
      height: ${scalePx(620, props.scale)}px;
      width: ${scalePx(877, props.scale)}px;
    `}
`;

export const FrameInset = styled.div`
  overflow: hidden;
`;

const posterOverlay = css`
  color: black;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1000;
`;

export const Gradient = styled.div`
  ${posterOverlay};
  
  padding-bottom: 20px;
  text-align: center;
  height: 200px;

  background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    ),
    linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 25%,
      rgba(255, 255, 255, 1) 100%
    );
  ${({ scale }) =>
    scale &&
    css`
      border-top: ${scalePx(3, scale)} solid black;
      height: ${scalePx(200, scale)}px;
      padding-bottom: ${scalePx(20, scale)}px;
    `}
`;

export const Bottom = styled.div`
  ${posterOverlay};

  background: white;
  text-align: center;
  
  ${({ scale }) =>
    scale &&
    css`
      border-top: ${scalePx(3, scale)} solid black;
      padding-bottom: ${scalePx(20, scale)}px;
      padding-top: ${scalePx(20, scale)}px;
    `}
`;

export const Center = styled.div`
  ${posterOverlay};

  padding-bottom: 20px;
  padding-top: 20px;
  text-align: center;
  
  ${({ scale }) =>
    scale &&
    css`
      border-top: ${scalePx(3, scale)} solid black;
      padding-bottom: ${scalePx(20, scale)}px;
      padding-top: ${scalePx(20, scale)}px;
    `}
`;

export const CenterBox = styled.div`
  padding: 10px 20px 20px 20px;
  background: white;

  ${({ scale }) =>
    scale &&
    css`
      padding: ${scalePx(10, scale)}px ${scalePx(20, scale)}px ${scalePx(20, scale)}px ${scalePx(20, scale)}px;
    `}
`;

export const Coordinates = styled.div`
  font-size: 18px;
  text-align: center;
  display: block;
  line-height: 24px;
  ${({ scale }) =>
    scale &&
    css`
      font-size: ${scalePx(2, scale)}px;
      font-size: ${scalePx(18, scale)}px;
      line-height: ${scalePx(24, scale)}px;
    `}
`;

export const Title = styled.div`
  font-size: 36px;
  text-transform: uppercase;
  font-weight: bold;
  ${({ scale }) =>
    scale &&
    css`
      font-size: ${scalePx(36, scale)}px;
    `}
`;
