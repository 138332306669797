import React from 'react';
import styled, { css } from 'styled-components';
import { media } from '../../../helpers/media';

const Container = styled.a`
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-align: center;
  border-left: 1px solid #cccccc;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 100%;
  user-select: none;

  &:hover {
    background-color: #f7f7f7;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: #f7f7f7;
    `}

  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }

  ${media.medium`
    border-left: none;
    border-bottom: 1px solid #cccccc;
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  `};
`;

const IconContainer = styled.div`
  padding: 10px;
`;

const Label = styled.div``;

const BottomNavButton = ({ active, Icon, label, onClick }) => (
  <Container onClick={onClick} active={active}>
    <IconContainer>
      <Icon size="30px" />
    </IconContainer>
    <Label>{label}</Label>
  </Container>
);

export default BottomNavButton;
