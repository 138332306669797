import { parse } from 'query-string';

export const initialState = {
  style: 'black',
  overlay: 'gradient',
  lat: 52.3679843,
  lng: 4.9035614,
  title: 'Amsterdam',
  subtitle: 'N52°22\'05" E4°54\'13"',
  zoom: 12,
  size: 'A3',
  parsed: false,
  useCoordinates: true,
  horizontal: false,
  placenames: false,
};

const parseStateFromUrl = () => {
  const state = parse(window.location.search);

  return {
    ...initialState,
    ...state,
    horizontal: state.horizontal === 'true',
    placenames: state.placenames === 'true',
    useCoordinates: state.useCoordinates !== 'false',
  };
};

export default parseStateFromUrl;
