
const getLocationFromBrowser = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      location => {
        console.log(location);
        resolve(location);
      },
      error => {
        reject(error);
      },
    );
  });
};

export default getLocationFromBrowser;
