import styled from 'styled-components';
import { media } from '../../helpers/media';

const Input = styled.input`
  border-radius: 5px;
  padding: .5rem;
  font-size: 14px;
  background: white;
  color: black;
  border: 1px solid #999;
  display: block;
  height: 51px;
  box-sizing: border-box;
  ${media.medium`
    width: 100%;
  `}
`;

export default Input;
