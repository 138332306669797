import styled, { css } from 'styled-components';

const Button = styled.button`
  display: inline-block;
  border-radius: 5px;
  padding: 1rem;
  font-size: 14px;
  background: white;
  color: black;
  border: 1px solid #999;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  margin-bottom: 10px;
  
  &:hover{
    transform: scale(1.05);
  }

  ${props =>
    props.primary &&
    css`
      background: #333744;
      color: white;
    `}

  ${props =>
    props.selected &&
    css`
      background: #333744;
      color: white;
    `}
`;

export default Button;
