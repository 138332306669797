import React, { useRef, useEffect, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import { LeafletMap, MapFrame } from './components';
import { TileLayer } from 'react-leaflet';
import { getMapUrl } from '../../helpers/getMapUrl';
import { SizeLabels } from './components/sizeLabels';
import { Map } from './components/map';
import styled, { css } from 'styled-components';
import { media, sizes } from '../../helpers/media';

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  ${props =>
    props.isPaneOpen &&
    css`
      ${media.medium`
      margin-left: 440px;
    `}
    `}
`;

const calcScale = ref => {
  let WIDTH_OFFSET = 140;
  let HEIGHT_OFFSET = 250;

  // non mobile
  if (window.innerWidth > sizes.medium) {
    HEIGHT_OFFSET = 150;
    WIDTH_OFFSET = 650; // sidebar and labels
  }
  const docHeight = window.innerHeight - HEIGHT_OFFSET;
  const docWidth = document.body.clientWidth - WIDTH_OFFSET;
  const mapWidth = ref.current.clientWidth;
  const mapHeight = ref.current.clientHeight;
  console.log(docWidth / mapWidth, docHeight / mapHeight);
  const scale = Math.min(docWidth / mapWidth, docHeight / mapHeight);
  if (scale >= 1) {
    return 1;
  }
  return scale;
};

const useCalculateScale = () => {
  const ref = useRef(null);
  const [transformScale, setTransformScale] = useState(1);
  useEffect(() => {
    setTransformScale(calcScale(ref));
    window.addEventListener('resize', () => {
      setTransformScale(calcScale(ref));
    });
  }, [ref]);
  return { transformScale, ref };
};

export const Dynamic = props => {
  const { transformScale, ref } = useCalculateScale();
  return (
    <Container isPaneOpen={props.isPaneOpen}>
      <MapFrame
        transformScale={transformScale}
        ref={ref}
        scale={props.scale}
        print={false}
        horizontal={props.horizontal}
      >
        <LeafletMap
          center={{ lat: props.lat, lng: props.lng }}
          ref={m => {
            if (m === null) {
              return;
            }
            m.leafletElement.invalidateSize();
          }}
          zoom={props.zoom}
          onZoomend={e => {
            return props.onZoom(e.target.getZoom());
          }}
          onMoveend={e => {
            return props.onLocation(e.target.getCenter());
          }}
        >
          <Map scale={props.scale} print={false} {...props}>
            <TileLayer
              attribution=""
              url={getMapUrl(false, props.style, props.location)}
            />
            <TileLayer
              attribution=""
              opacity={props.placenames ? '1' : '0'}
              url={getMapUrl(false, 'labels_modern', props.location)}
            />
          </Map>
        </LeafletMap>
        <SizeLabels horizontal={props.horizontal} size={props.size} />
      </MapFrame>
    </Container>
  );
};
