export const formats = [
  {
    size: 'A4',
    price: '€24,95',
    mm: {
      w: 210,
      h: 297,
    },
  },
  {
    size: 'A3',
    price: '€29,95',
    mm: {
      w: 297,
      h: 420,
    },
  },
  {
    size: 'A2',
    price: '€34,95',
    mm: {
      w: 420,
      h: 594,
    },
  },
  {
    size: 'A1',
    price: '€39,95',
    mm: {
      w: 594,
      h: 841,
    },
  },
  {
    size: 'A0',
    price: '€44,95',
    mm: {
      w: 841,
      h: 1189,
    },
  },
];

export const mapStyles = [
  {
    name: 'Printmijnstad',
    slug: 'black',
  },
  {
    name: 'Tekening',
    slug: 'mapbox-studio-pencil',
  },
  {
    name: 'Zwart Wit',
    slug: 'black-inverse',
  },
  {
    name: 'Contrast',
    slug: 'high_contrast',
  },
  {
    name: 'Contrast Kleur',
    slug: 'x',
  },
  {
    name: 'Zwart',
    slug: 'very-dark',
  },
  {
    name: 'Goud',
    slug: 'gold',
  },
  {
    name: 'Antiek',
    slug: 'neighborhood-antique',
  },
  {
    name: 'Landkaart',
    slug: 'mapbox-studio-outdoors',
  },
  {
    name: 'Minimaal',
    slug: 'minimal',
  },
  {
    name: 'jauntful_map',
    slug: 'jauntful_map',
  },
  {
    name: 'Rood',
    slug: 'red',
  },
  {
    name: 'Blauw',
    slug: 'blue',
  },
  {
    name: 'Roze',
    slug: 'super-pink',
  },
];

export const overlays = [
  {
    name: 'Gratient',
    slug: 'gradient',
  },
  {
    name: 'Minimaal',
    slug: 'minimal',
  },
  {
    name: 'Midden',
    slug: 'center',
  },
  {
    name: 'Onderkant',
    slug: 'bottom',
  },
];
