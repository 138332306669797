// This function returns the coordinate
// conversion string in DD to DMS.
export const ddToDms = (lat, lng) => {
  let latResult, lngResult, dmsResult;

  lat = parseFloat(lat);
  lng = parseFloat(lng);

  latResult = lat >= 0 ? 'N' : 'S';

  // Call to getDms(lat) function for the coordinates of Latitude in DMS.
  // The result is stored in latResult variable.
  latResult += getDms(lat);

  lngResult = lng >= 0 ? 'E' : 'W';

  // Call to getDms(lng) function for the coordinates of Longitude in DMS.
  // The result is stored in lngResult variable.
  lngResult += getDms(lng);

  // Joining both variables and separate them with a space.
  dmsResult = latResult + ' ' + lngResult;

  // Return the resultant string
  return dmsResult;
};

export const getDms = val => {
  let valDeg, valMin, valSec, result;

  val = Math.abs(val);

  valDeg = Math.floor(val);
  result = valDeg + '\u00B0';

  valMin = Math.floor((val - valDeg) * 60);
  result += ('0' + Math.round(valMin)).slice(-2) + "'";

  valSec = Math.round((val - valDeg - valMin / 60) * 3600 * 1000) / 1000;
  result += ('0' + Math.round(valSec)).slice(-2) + '"';

  return result;
};
