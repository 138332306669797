import React from 'react';
import PaneNavigation from '../components/paneNavigation/paneNavigation';
import Button from '../components/button/button';
import { AppConsumer } from '../context';
import { formats } from '../config';
import styled from 'styled-components';
import { media } from '../helpers/media';

const Container = styled.div`
  button {
    margin-left: 5px;
    margin-bottom: 5px;
    width: calc(50% - 5px);
    box-sizing: border-box;
  }
  
  ${media.medium`
    button {
      margin-left: 0;
      margin-bottom: 10px;
      width: 100%;
    }
  `}
`;

const SizePane = ({ onClose }) => (
  <AppConsumer>
    {({ handleUpdate, size }) => (
      <PaneNavigation onClose={onClose}>
        <h2>Kies je formaat</h2>
        <Container>
          {formats.map(({ size: itemSize, price, mm: { w, h } }) => (
            <Button
              onClick={() => handleUpdate('size', itemSize)}
              primary={itemSize === size}
            >
              <div>
                {itemSize} ({price})
              </div>
              <small>
                {w} mm x {h} mm
              </small>
            </Button>
          ))}
        </Container>
      </PaneNavigation>
    )}
  </AppConsumer>
);

export default SizePane;
