import React from 'react';
import { Print } from './print';
import { Dynamic } from './dynamic';

export const DesignedMap = ({
  isPaneOpen,
  print,
  onZoom,
  onLocation,
  size,
  ...props
}) => {
  if (print) {
    return <Print {...props} />;
  }
  return (
    <Dynamic
      isPaneOpen={isPaneOpen}
      onZoom={onZoom}
      onLocation={onLocation}
      size={size}
      {...props}
    />
  );
};

export default DesignedMap;
