const GOOGLE_URL =
  'https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyAgptgbRLVo1bv4Wtzj_Q-z75P7Q8EmOtk';

const parseData = data => {
  const [{ address_components }] = data.results;

  // TODO what if not exists
  let city_component = address_components.filter(component =>
    component.types.includes('locality'),
  );

  if (city_component.length === 0) {
    city_component = address_components.filter(component =>
      component.types.includes('administrative_area_level_1'),
    );
  }

  const [{ long_name }] = city_component;

  const [
    {
      geometry: {
        location: { lat, lng },
      },
    },
  ] = data.results;

  return {
    lat,
    lng,
    city: long_name,
  };
};

const queryGoogle = async (query) => {
  const results = await fetch(query);
  const response = await results.json();
  return parseData(response);
}

export const geocode = async (address, language = 'nl') => {
  return queryGoogle(
    `${GOOGLE_URL}&language=${language}&address=${address}`,
  );
};

export const geocodeCity = async (latitude, longitude, language = 'nl') => {
  return queryGoogle(
    `${GOOGLE_URL}&language=${language}&latlng=${latitude},${longitude}&sensor=true`,
  )
};
