import React, { Fragment } from 'react';
import { AppProvider } from './context';
import { Main, Print } from './pages';
import isPrintMode from './helpers/isPrintMode';
import { createGlobalStyle } from 'styled-components';
import isPreviewMode from './helpers/isPreviewMode';
import styled from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: #f7f4f4;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
`;

const PreviewContainer = styled.div`
  width: 2400px;
  height: 1260px;
  background: url(https://ae01.alicdn.com/kf/HTB1dc2.r7SWBuNjSszdq6zeSpXao/7x5ft-Wall-and-Floor-Theme-Vinyl-Photography-Backdrops-Prop-Photo-Studio-Background-JQD-269.jpg);
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
`;

const getComponent = () => {
  if (isPrintMode()) {
    return <Print />;
  } else if (isPreviewMode()) {
    return (<PreviewContainer>
        <Print scale={1} />
    </PreviewContainer>);
  }

  return (
    <AppProvider>
      <Main key='main' />
    </AppProvider>
  );
};

export default () => {
  return (
    <Fragment>
      <GlobalStyle />
      {getComponent()}
    </Fragment>
  );
};
