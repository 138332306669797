import React, { useState, useEffect } from 'react';
import { parse } from 'query-string';
import { geocode, geocodeCity } from '../helpers/geocode';
import { stringify } from 'query-string';
import parseStateFromUrl, { initialState } from '../helpers/parseStateFromUrl';
import { ddToDms } from '../helpers/ddToDms';

export const getUrl = (currentUrl, state) => {
  return `?${stringify({
    ...currentUrl,
    lng: state.lng,
    lat: state.lat,
    style: state.style,
    title: state.title,
    subtitle: state.subtitle,
    zoom: state.zoom,
    horizontal: state.horizontal,
    size: state.size,
    placenames: state.placenames,
    useCoordinates: state.useCoordinates,
    overlay: state.overlay,
  })}`;
};

const createUrlFromState = state => {
  const currentUrl = parse(window.location.search);
  window.history.replaceState(null, null, `${getUrl(currentUrl, state)}`);
};

const Context = React.createContext({
  handleUpdate: () => console.log('update'),
});

const getStateFromAddress = async address => {
  try {
    const { lat, lng, city } = await geocode(address);
    return {
      lat: lat,
      lng: lng,
      title: city,
      zoom: 12,
      subtitle: ddToDms(lat, lng),
    };
  } catch (error) {
    console.log(error);
    return {};
  }
};

const useManageState = () => {
  // @TODO change this to a reducer instead :)
  const [state, setState] = useState(initialState);

  useEffect(() => {
    const url = parse(window.location.search);
    let newState = {};
    (async () => {
      if (url.location) {
        newState = await getStateFromAddress(url.location);
      }
      if (url.plaatsnaam) {
        newState = await getStateFromAddress(url.plaatsnaam);
      }

      setState({
        ...parseStateFromUrl(),
        ...newState,
        parsed: true,
      });
    })();
  }, []);

  useEffect(() => {
    if (!state.parsed) {
      return;
    }
    createUrlFromState(state);
  }, [state]);

  return { state, setState };
};

const AppProvider = ({ children }) => {
  const { state, setState } = useManageState();

  if (!state.parsed) {
    return null;
  }

  console.log('Current state', state);
  return (
    <Context.Provider
      value={{
        ...state,
        handleLocationFromBrowser: async result => {
          console.log('handle location from browser');
          try {
            const { lat, lng, city } = await geocodeCity(
              result.lat,
              result.lng,
            );
            setState({
              ...state,
              title: city,
              zoom: 12,
              subtitle: ddToDms(lat, lng),
              lat: lat,
              lng: lng,
            });
          } catch (error) {
            console.error(error);
          }
        },
        handleSearchAddress: async address => {
          console.log('Searching location', address);
          const addressState = await getStateFromAddress(address);
          setState({
            ...state,
            ...addressState,
          });
        },
        handleUpdate: (key, value) => {
          console.log('Setting state', key, value);
          setState({
            ...state,
            [key]: value,
          });
        },
        setUseCoordinates: useCoordinates => {
          let subtitle = state.subtitle;
          if (useCoordinates) {
            subtitle = ddToDms(state.lat, state.lng);
          }

          setState({
            ...state,
            useCoordinates: useCoordinates,
            subtitle: subtitle,
          });
        },
        handleUpdateLocation: ({ lat, lng }) => {
          console.log('Update location', lat, lng, state);
          if (String(lat) === state.lat && String(lng) === state.lng) {
            console.log('skipping update');
            return;
          }

          let subtitle = state.subtitle;
          if (state.useCoordinates) {
            subtitle = ddToDms(lat, lng);
          }

          setState({
            ...state,
            lat: lat,
            lng: lng,
            subtitle: subtitle,
          });
        },
      }}
    >
      {children}
    </Context.Provider>
  );
};

const AppConsumer = Context.Consumer;

export { AppProvider, AppConsumer };
