import React from 'react';
import styled from 'styled-components';
import BottomNavButton from './components/bottomNavButton';
import { media } from '../../helpers/media';
import {
  MdLocationOn,
  MdStyle,
  MdTextFields,
} from 'react-icons/md';
import { IoIosResize } from "react-icons/io";

const Container = styled.div`
  display: flex;
  background-color: white;
  flex-direction: row;
  position: fixed;
  height: 90px;
  width: 100%;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  justify-content: space-between;
  z-index: 10000;
  bottom: 60px;
  
  ${media.medium`
    border-top: 0px;
    flex-direction: column;
    position: initial;
    height: calc(100vh - 60px - 60px); // - header - buy button
    width: 90px;
    justify-content: flex-start;
  `}
`;

const navItems = [
  { pane: 'location', label: 'Locatie', Icon: MdLocationOn },
  { pane: 'styles', label: 'Stijlen', Icon: MdStyle },
  { pane: 'texts', label: 'Teksten', Icon: MdTextFields },
  { pane: 'complete', label: 'Formaat', Icon: IoIosResize },
];

const Nav = ({ selectedPane, onClick }) => (
  <Container>
    {navItems.map(({ pane, ...rest }) => (
      <BottomNavButton active={selectedPane === pane} key={pane} onClick={() => onClick(pane)} {...rest} />
    ))}
  </Container>
);

export default Nav;
