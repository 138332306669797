import React from 'react';
import styled from 'styled-components';
import PaneNavigation from '../components/paneNavigation/paneNavigation';
import MapStyle, { OverlayStyle } from '../components/mapStyle/mapStyle';
import { AppConsumer } from '../context';
import { media } from '../helpers/media';
import { mapStyles, overlays } from '../config';
import Label from '../components/label';

const MapStyles = styled.div`
  overflow-y: scroll;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${media.medium`
    overflow-y: scroll;
    flex-direction: column;
    display: inline-block;
  `}
`;

const MapStylesContainer = styled.div`
  ${media.medium`
    max-height: calc(100% - 60px);
    overflow-y: scroll;
    flex-direction: column;
    display: inline-block;
    margin-left: 10px;
  `}
`;

const Text = styled.h2``;

const StylePane = ({ onClose }) => (
  <PaneNavigation onClose={onClose}>
    <Text>Kies je stijl</Text>
    <AppConsumer>
      {({ style, overlay, handleUpdate }) => (
        <MapStylesContainer>
          <Label>Poster stijl</Label>
          <MapStyles>
            {overlays.map(({ slug, name }) => (
              <OverlayStyle
                key={slug}
                onClick={() => handleUpdate('overlay', slug)}
                style={name}
                mapStyleId={slug}
                active={overlay === slug}
              />
            ))}
          </MapStyles>
          <Label>Kaart stijl</Label>
          <MapStyles>
            {mapStyles.map(({ slug, name }) => (
              <MapStyle
                key={slug}
                onClick={() => handleUpdate('style', slug)}
                style={name}
                mapStyleId={slug}
                active={style === slug}
              />
            ))}
          </MapStyles>
        </MapStylesContainer>
      )}
    </AppConsumer>
  </PaneNavigation>
);

export default StylePane;
