import styled from 'styled-components';
import React from 'react';
import { AppConsumer } from '../../context';
import { MdAddShoppingCart } from 'react-icons/md';
import { getUrl } from '../../context/app';
import { media } from '../../helpers/media';
import { getFormatBySize } from '../../helpers/getFormatBySize';

const GreenButton = styled.a`
  box-sizing: border-box;
  text-decoration: none;
  padding: 1rem;
  font-size: 14px;
  background: #008525;
  height: 60px;
  width: 100%;
  color: white;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  text-align: center;
  border-left: 1px solid #cccccc;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: fixed;
  bottom: 0;
  z-index: 10001;
  animation: pulse 2s infinite;
  box-shadow: 0 0 0 rgba(0, 133, 37, 0.4);
  transition: all 0.2s ease-in-out;
  line-height: 30px;

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(0, 133, 37, 0.4);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(0, 133, 37, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(0, 133, 37, 0);
    }
  }

  &:hover {
    transform: scale(1.05);
  }

  ${media.medium`
    bottom: 0;
    display: inline-block;
    max-width: 440px;
    border-top: none;
    border-right: 1px solid #3337444d;
    border-bottom: 1px solid #3337444d;
    border-top: none;
  `}
`;

const ShoppingIcon = styled(MdAddShoppingCart)`
  position: absolute;
  right: 10px;
`;

const BuyButton = () => {
  return (
    <AppConsumer>
      {({ ...state }) => (
        <GreenButton
          href={`https://poster.printmijnstad.nl/order${getUrl({}, state)}`}
          key={state.size}
        >
          <ShoppingIcon size="30px" />
          <div>
            Bestelling plaatsen ({state.size} poster{' '}
            <strong>{getFormatBySize(state.size).price}</strong>)
          </div>
        </GreenButton>
      )}
    </AppConsumer>
  );
};

export default BuyButton;
