import React from 'react';
import { FakeMap, MapFrame } from './components';
import { getMapUrl } from '../../helpers/getMapUrl';
import { Map } from './components/map';

export const Print = props => {
  return (
    <MapFrame scale={props.scale} print={true} horizontal={props.horizontal}>
      <FakeMap>
        <Map scale={props.scale} print={true} {...props}>
          <img
            alt={props.title}
            src={getMapUrl(
              true,
              props.style,
              props.lat,
              props.lng,
              props.zoom,
              props.horizontal,
              props.scale,
            )}
          />
          {props.placenames && (
            <img
              alt={props.title}
              style={{
                position: 'absolute',
                left: '0',
              }}
              src={getMapUrl(
                true,
                'labels_modern',
                props.lat,
                props.lng,
                props.zoom,
                props.horizontal,
                props.scale,
              )}
            />
          )}
        </Map>
      </FakeMap>
    </MapFrame>
  );
};
