import { MdRotate90DegreesCcw } from 'react-icons/md';
import React from 'react';
import styled from 'styled-components';
import { media } from '../../helpers/media';

const Button = styled(MdRotate90DegreesCcw)`
  margin: 0;
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 170px;
  right: 20px;
  background: white;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.25);

  ${media.medium`
    bottom: 20px;
    right: 20px;
    z-index: 1000;
   `}
`;

export const RotateButton = ({ onRotate }) => <Button onClick={onRotate} />;
