const MAP_SERVER = 'https://kaartenzijncool.printmijnstad.nl';

export const getMapUrl = (
  print,
  style,
  lat,
  lng,
  zoom = 0,
  horizontal = false,
  scale = 8,
) => {
  if (!print) {
    return `${MAP_SERVER}/${style}/{z}/{x}/{y}@2x.png`;
  }

  const urlScale = scale > 1 ? `@${scale}x` : '';

  return `${MAP_SERVER}/${style}/static/${lng},${
    lat
  },${zoom}/${horizontal ? '877x620' : '620x877'}${urlScale}.png`;
};
